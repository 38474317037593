import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Texts from '../Texts';
import DropDown from '../DropDown';

const Projects = ({ title, classes }) => {
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        pages {
          projects {
            content
            tissue_engineering {
              title
              content
            }
          }
        }
      }
    }
  `);

  const { content, tissue_engineering: tisEng } = dataJson.pages.projects;

  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <Texts content={content} />
      <DropDown title={tisEng.title}>
        <Texts content={tisEng.content} />
      </DropDown>
    </div>
  );
};

Projects.defaultProps = {
  title: null,
};

Projects.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

export default Projects;
